import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, takeUntil, catchError, Subject } from 'rxjs';
import { selectUser } from '../../store/user/selectors/user.selector';
import { NavigationService } from '../navigation/navigation.service';
import { RegistrationForm } from '../../store/sign-up/models/sign-up.model';
interface AccessToken {
  data: string;
}
interface UserLogin {
  login: string;
  password: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private store: Store,
    private navigation: NavigationService
  ) {}
  public loginData: UserLogin = {
    login: '',
    password: '',
  };

  unsubus$: Subject<boolean> = new Subject();
  user$: Observable<any> = this.store.select(selectUser);

  blockAuthPages(): Observable<boolean> {
    return new Observable((observer) => {
      this.user$.pipe(takeUntil(this.unsubus$)).subscribe((res: any) => {
        if (res.loaded) {
          if (res?.isAuth) {
            this.navigation.navigateTo('/');
            observer.next(false);
          }
          observer.next(true);
          this.unsubus$.next(true);
          this.unsubus$.complete();
        }
      });
    });
  }
  getIsUserAuth(): Observable<boolean> {
    return new Observable((observer) => {
      this.user$.pipe(takeUntil(this.unsubus$)).subscribe((res: any) => {
        if (res.loaded) {
          if (!res.isAuth) {
            this.navigation.navigateTo('/auth/sign-in');
          }
          observer.next(true);
          this.unsubus$.next(true);
          this.unsubus$.complete();
        }
      });
    });
  }

  login(data: any) {
    return this.http.post('/auth/auth/login', data);
  }

  logout() {
    return this.http
      .post(`/auth/auth/logout`, {})
      .pipe(
        catchError((e) => {
          throw new Error(`Unable to log out: ${e}`);
        })
      )
      .subscribe(() => {
        window.location.href = '/';

        sessionStorage.removeItem('ACCESS_TOKEN');
      });
  }

  getAccessToken(): Observable<AccessToken> {
    return this.http.get<AccessToken>(`/auth/auth/access-token`, {
      withCredentials: true,
    });
  }

  getRefreshToken(token: string) {
    return this.http.post(`/auth/auth/refresh-token`, token, {
      withCredentials: true,
    });
  }

  isAccessValid(): Observable<boolean> {
    return this.http.post<boolean>(`/auth/auth/validate-access`, null, {
      withCredentials: true,
    });
  }

  public checkRegisterParams(params: RegistrationForm) {
    const updatedRegistrationFormData = {
      phone: String(params.phoneCode) + String(params.phoneNumber),
      email: params.email,
      currency: params.currency,
    };

    return this.http.post('v1/user/validate/registration', updatedRegistrationFormData);
  }

  generateRandomPostalCode() {
    const length: number = Math.floor(Math.random() * 2) + 1;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return 'srwelle' + result[length - 1];
  }

  generateRandomString() {
    const length = Math.floor(Math.random() * 6) + 2;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return 'srwelle' + result;
  }
  register(params: RegistrationForm) {
    const formattedData = this.transformParams(params);

    return this.http.post('/auth/auth/sign-up', {
      ...formattedData,
    });
  }

  transformParams(params: any) {
    return {
      email: params.email,
      password: params.password,
      phone: String(params.phoneCode).replace('+', '') + String(params.phoneNumber),
      currency: params.currency,
      profile: {
        phoneCode: params.phoneCode,
        phoneNumber: params.phoneNumber,
        firstName: params.firstName,
        lastName: params.lastName,
        gender: params.gender,
        dateOfBirth: this.formatDateOfBirthToDate(params.birthDay, params.birthMonth, params.birthYear),
        countryIso3: params.country.iso3,
        countryiso2: params.country.iso2,
        country: params.country.name,
        city: params.city,
        address: params.address,
        postalCode: params.postalCode,
        politicallyExposedPerson: params.politicallyExposedPerson.toLowerCase() === 'no' ? false : true,
      },
    };
  }

  formatDateOfBirthToDate(birthDay?: string, birthMonth?: string, birthYear?: string): Date {
    const day = Number(birthDay);
    const month = Number(birthMonth) - 1;
    const year = Number(birthYear);
    const dateObj = new Date(year, month, day);
    return dateObj;
  }
}
