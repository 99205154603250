import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DefaultCategories, MerchantList, SliderJson } from 'src/app/core/interfaces/interfaces';
import { Store } from '@ngrx/store';
import { appState } from 'src/app';
import { HelpersService } from 'src/app/core/services/helpers/helpers.service';
import { KeystoneService } from 'src/app/core/services/keystone/keystone.service';
import { GamesService } from 'src/app/core/services/gameCatalog/games.service';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FilterActions } from 'src/app/core/store/filters/actions/filter.actions';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { ModalsActions } from 'src/app/core/store/modals/actions/modals.actions';
import { selectModal } from 'src/app/core/store/modals/selector/modals.selectors';
import { LocalStorageService } from 'src/app/core/services/localStorage/local-storage.service';
import { TranslocoModule } from '@ngneat/transloco';
import { selectFilter } from 'src/app/core/store/filters/selectors/filter.selector';
import { selectHelpers } from 'src/app/core/store/helpers/selector/helpers.selectors';
import { selectUser } from 'src/app/core/store/user/selectors/user.selector';

@Component({
  selector: 'app-filters',
  standalone: true,
  imports: [TranslocoModule, NgIf, NgForOf, NgClass],
  templateUrl: './filters.component.html',
  styleUrl: './filters.component.scss',
})
export class FiltersComponent implements OnDestroy, OnInit {
  @Output()
  closeEmitter: EventEmitter<any> = new EventEmitter<any>();
  lang: string = 'en';

  modal$: Observable<any> = this.store.select(selectModal);
  filters$: Observable<any> = this.store.select(selectFilter);
  helpers$: Observable<any> = this.store.select(selectHelpers);
  user$: Observable<any> = this.store.select(selectUser);
  currentFilter: string = '';

  fliteredCategories: any;
  defaultCategories: Array<DefaultCategories> = [
    {
      slug: 'favorites',
      isAuth: true,
      img: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/favorites.svg',
    },
    {
      slug: 'last_played',
      isAuth: true,
      img: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/last_played.svg',
    },
    {
      slug: 'all',
      isAuth: false,
      img: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/all_games.svg',
    },
  ];

  showMoreBtn: boolean = false;
  hideLessBtn: boolean = false;

  merchantsList: Array<MerchantList> = [];

  sliderJson: Array<SliderJson> = [];
  checkedIds: Array<string> = [];

  decoy: Array<boolean> = new Array(15).fill(false);
  isLoaded: boolean = false;
  isMobile: boolean = false;

  selectedMerchants: Array<MerchantList> = [];
  allMerchantsList: Array<MerchantList> = [];
  categoriesList: Array<DefaultCategories> = [];

  showMoreCategoriesList: any[] = [];
  showMoreMerchantsList: any[] = [];

  categories: boolean = false;
  providers: boolean = false;

  checkboxes: any;
  screenWidth: number = 0;

  unsubus$: Subject<boolean> = new Subject();
  isAuth: boolean = false;
  currentScreenWidth: number = 1920;

  constructor(
    private store: Store<appState>,
    private helpers: HelpersService,
    private keystone: KeystoneService,
    private games: GamesService,
    private router: Router,
    private navigation: NavigationService,
    private localStorage: LocalStorageService
  ) {
    this.lang = this.helpers.getCookie('userlang') || 'en';
    this.helpers$.pipe(takeUntil(this.unsubus$)).subscribe((res) => {
      if (res.isLoaded) {
        this.currentScreenWidth = res?.screenWidth;
        this.isMobile = res.isMobile;
        this.screenWidth = res.screenWidth;
      }
    });
    this.user$.pipe(takeUntil(this.unsubus$)).subscribe((res) => {
      if (res.loaded) {
        this.isAuth = res.isAuth;
      }
    });
    this.filters$.pipe(takeUntil(this.unsubus$)).subscribe((res) => {
      if (res.isLoaded) {
        this.checkSelectedProviders(res);
        if (res.clear && this.checkedIds.length == 0) this.removeSelected();
      }
    });
    this.providers = true;
  }
  ngOnInit() {
    this.isLoaded = false;
    this.currentFilter = this.localStorage.getSessionStorage('filters') || '';
    if (this.currentFilter === 'providers') {
      this.getMerchants();
    } else {
      this.getCategories();
    }
    this.checkIfSelected();
  }

  checkIfSelected() {
    if (this.checkedIds.length > 0) {
      for (let i = 0; i < this.checkedIds.length; i++) {
        const selectedProviders: any = this.allMerchantsList.find(({ id }) => id === this.checkedIds[i]);
        this.selectedMerchants.push(selectedProviders);
      }
    }
  }

  imgForProviders(merchantsList: any) {
    if (this.sliderJson.length == 0) {
      this.keystone
        .getBanner('provider-logos')
        .pipe(takeUntil(this.unsubus$))
        .subscribe((response: any) => {
          this.sliderJson = [];
          this.filterBanners(response?.pages, merchantsList);
        });
    }
  }
  filterBanners(data: any, merchantsList: any) {
    data.forEach((elem: any) => {
      const a = this.helpers.jsonConvert(elem.content);
      this.sliderJson.push(a);
      merchantsList.forEach((element: any) => {
        if (element.id !== '999999') {
          if (element?.menuId == a?.menuId) {
            element.imageLogo = a.image;
          }
        }
      });
    });

    this.localStorage.setSessionStorage('merchantsList', JSON.stringify(merchantsList));
    this.showLess();
    this.isLoaded = true;
    return data;
  }

  getCategories() {
    const savedCategoriesList = JSON.parse(this.localStorage.getSessionStorage('categoriesList') || '[]');
    if (savedCategoriesList.length > 0) {
      this.categoriesList = savedCategoriesList;
      this.isLoaded = true;
      this.showLess();
      return;
    }

    this.games.getAllCategoryList(this.lang).subscribe((res: any) => {
      let categories = this.helpers.handleCategories(res);
      categories = categories.sort((a: any, b: any) => {
        return +b.cSort - +a.cSort;
      });
      this.categoriesList = [...categories];
      this.localStorage.setSessionStorage('categoriesList', JSON.stringify(this.categoriesList));
      this.showLess();
      this.categoriesList = this.categoriesList.sort((a: any, b: any) => {
        return a.slug > b.slug ? 1 : a.slug < b.slug ? -1 : 0;
      });
      this.isLoaded = true;
    });
  }
  showMore() {
    if (this.currentFilter == 'categories') {
      this.categoriesList = [...this.categoriesList, ...this.showMoreCategoriesList];
      this.showMoreBtn = false;
      return;
    }
    if (this.currentFilter == 'providers') {
      this.merchantsList = [...this.merchantsList, ...this.showMoreMerchantsList];
      this.merchantsList.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0
      );
      this.showMoreBtn = false;
      return;
    }
  }
  checkSelectedProviders(data: any) {
    this.checkedIds = [...data.providers];
  }

  showLess() {
    if (this.currentFilter == 'categories') {
      if (this.categoriesList.length == 9) {
        this.hideLessBtn = true;
        return;
      }
      if (this.categoriesList.length > 10) {
        this.showMoreCategoriesList = this.categoriesList.splice(10);
        this.showMoreBtn = true;
      }
      return;
    }
    if (this.currentFilter == 'providers') {
      this.hideLessBtn = false;
      if (this.merchantsList.length > 14) {
        this.showMoreMerchantsList = this.merchantsList.sort((a, b) => b.weight - a.weight).splice(14);
        this.showMoreBtn = true;
      }
      return;
    }
  }

  getMerchants() {
    const savedMerchantsList = JSON.parse(this.localStorage.getSessionStorage('merchantsList') || '[]');
    this.allMerchantsList = JSON.parse(this.localStorage.getSessionStorage('merchantsList') || '[]');
    if (savedMerchantsList.length > 0) {
      this.merchantsList = savedMerchantsList;
      this.isLoaded = true;
      this.showLess();
      return;
    }
    this.games
      .getAllMerchants()
      .pipe(takeUntil(this.unsubus$))
      .subscribe((res) => {
        this.merchantsList = res;
        this.imgForProviders(this.merchantsList);
      });
  }

  onCheck(item: any) {
    if (this.selectedMerchants.length == 0) {
      this.checkedIds.push(item.id);
      this.selectedMerchants.push(item);
      return;
    }
    const a = this.selectedMerchants.length;
    this.checkedIds = this.checkedIds.filter((el: any) => {
      return el !== item.id;
    });
    this.selectedMerchants = this.selectedMerchants.filter((el: any) => {
      return el?.id !== item?.id;
    });
    if (a !== this.selectedMerchants.length) {
      return;
    } else {
      this.checkedIds.push(item.id);
      this.selectedMerchants.push(item);
    }
  }
  selectCategory(slug: any) {
    this.closeModal();
    this.store.dispatch(
      FilterActions['[SetFilter]SetFilterCategorySuccess']({
        data: slug,
      })
    );
    this.router.navigate(['/games', slug]);
    this.closeEmitter.emit(false);
  }
  removeSelected() {
    this.checkedIds = [];
    this.selectedMerchants = [];
  }
  closeModal() {
    this.store.dispatch(ModalsActions['[modals]CloseModalsSuccess']());
  }
  applyFilters() {
    window.scrollTo(0, 0);
    const data = {
      ids: this.selectedMerchants.map((elem: any) => {
        return elem?.id;
      }),
      names: this.selectedMerchants.map((elem: any) => {
        return elem?.name;
      }),
    };

    this.store.dispatch(
      FilterActions['[SetFilter]SetFilterProvidersSuccess']({
        data: data.ids,
        names: data.names,
      })
    );
    this.closeModal();
    this.closeEmitter.emit(false);
    if (!this.router.url.includes('games')) {
      this.navigation.navigateTo('/games/all');
    }
  }
  ngOnDestroy(): void {
    this.unsubus$.next(true);
    this.unsubus$.complete();
  }
  openModal(modal: string) {
    this.currentFilter = modal;
    this.localStorage.setSessionStorage('filters', modal);
    this.store.dispatch(
      ModalsActions['[modals]OpenModalSuccess']({
        id: 'game-filters',
      })
    );
    if (this.currentFilter === 'providers') {
      this.getMerchants();
    } else {
      this.getCategories();
    }
  }
}
