import { createReducer, on } from '@ngrx/store';
import { GetBonuses, SetBonuses } from '../actions/bonus.actions';

export const configFeatureKey = 'bonuses';

export interface State {
  data: Array<any>;
  activeBonus: Array<any>;
  isLoaded: boolean;
}

export const initialState: State = {
  data: [],
  activeBonus: [],
  isLoaded: false,
};

export const reducer = createReducer(
  initialState,
  on(GetBonuses['[GetBonuses]GetBonuses'], (state) => {
    return {
      ...state,
    };
  }),
  on(SetBonuses['[SetBonuses]SetBonusesSuccess'], (state, action) => {
    return {
      ...state,
      data: action.data,
      // activeBonus: action.data.filter((el: any) => el.Active == 1),
      isLoaded: true,
    };
  }),
  on(SetBonuses['[SetBonuses]SetBonusesFailure'], (state) => {
    return {
      ...state,
      data: [],
      activeBonus: [],
      isLoaded: true,
    };
  })
);
