import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { NavigationService } from '../navigation/navigation.service';
import { currencyInterface, GeoCurrency, PaymentsVerification, withdrawDescription } from '../../interfaces/interfaces';
import { HelpersService } from '../helpers/helpers.service';
import { LimitPeriod, ResultDate, TypeLimit } from '../../interfaces/interfaces';
import { DateBuilder } from '../../utils/date-builder';
import { of } from 'rxjs';
import { KeystoneService } from '../keystone/keystone.service';
import { LocalStorageService } from '../localStorage/local-storage.service';
@Injectable({
  providedIn: 'root',
})
export class FinancesService {
  makeDepositRegistration: EventEmitter<any> = new EventEmitter<any>();
  iframeDepositOutput: EventEmitter<any> = new EventEmitter<any>();

  cashforoUrls: any = {
    prod: 'https://cashforo.com',
    test: 'https://staging-cashforo.com',
  };
  constructor(
    private http: HttpClient,
    private navigator: NavigationService,
    private helpers: HelpersService,
    private keystone: KeystoneService,
    private storage: LocalStorageService
  ) {}
  payment: EventEmitter<any> = new EventEmitter<any>();

  filterValidPayments(data: any, paymentsToFind: PaymentsVerification[], isVerified?: boolean) {
    return data.filter((element: any) => {
      const description = this.helpers.jsonConvert(element?.description_withdraw);
      const isSafe = description?.safe;
      return isSafe || isVerified;
    });
  }

  filterNotValidPayments(data: any, paymentsToFind: PaymentsVerification[], isVerified?: boolean) {
    if (paymentsToFind.length !== 0) {
      return data;
    }
    return data.filter((element: any) => {
      const description = this.helpers.jsonConvert(element?.description_withdraw);
      const isSafe = !description?.safe;
      // ----
      const isVerifiedPayment = isVerified ? paymentsToFind.length !== 0 : true;
      return isSafe && isVerifiedPayment;
    });
  }

  filterPaymentsBySlug(payments: any[], slug: string) {
    if (slug === 'all') {
      return payments;
    }
    return payments.filter((payment: any) => payment.category.toLowerCase().includes(slug));
  }

  filterCryptoPayments(payments: any, selectedBonusId: any = null) {
    const params: any = this.navigator.getQueryParams();
    if (params.bonus == '72178' || selectedBonusId == '72178') {
      payments = payments.filter((elem: any) => elem.tags.includes('crypto'));
    }
    return payments;
  }

  public getTransactions() {
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    const previousWeek = new Date();
    previousWeek.setDate(previousWeek.getDate() - 30);

    const payload = {
      create_date: {
        gt: previousWeek.toISOString(),
        lt: nextDay.toISOString(),
      },
    };

    return this.http.post('/api/v2/payments/transactions/get', payload).pipe(
      map((data: any): any => {
        return data;
      })
    );
  }

  deleteWithdrawTransaction(transactionId: string) {
    return this.http.post(`/api/v2/payments/withdraw/cancel`, { transactionId });
  }

  getPaymentMethods(source: 'deposit' | 'withdraw' | 'all' = 'all') {
    return this.http.post<any[]>(`/api/v2/payments/catalog`, {}).pipe(
      map((obj: any) => {
        const result: any = [];
        if (source === 'all') {
          return obj;
        }
        obj.map((element: any) => {
          if (element?.type.toLowerCase().indexOf(source) > -1 || element?.type.toLowerCase() === 'all') {
            result.push(element);
          }
        });
        return result;
      })
    );
  }

  getCurrencies() {
    return this.http.get('/currencies').pipe(
      map((obj: any) => {
        const result: any = [];
        const newobj = {
          ...obj,
        };
        if (Array.isArray(newobj)) {
          obj.forEach((element: any) => {
            if (element?.registration) {
              result.push({
                value: element.Name,
                title: element.Name,
                ...element,
              });
            }
          });
          return result;
        } else {
          for (const i in obj) {
            if (obj[i]?.registration) {
              result.push({
                value: obj[i]?.Name,
                title: obj[i]?.Name,
                ...obj[i],
              });
            }
          }
          return result;
        }
      })
    );
  }

  getWithdrawLimits(method: string, currency: string, amount: number, userId: string) {
    return this.http.post('/api/v2/withdraw-limits/check-limits', {
      method: method,
      currency,
      merchantId: '23848259', // Welle merchantId
      amount,
      userId,
    });
  }

  getLimitsDate(period: LimitPeriod): { startDate: Date; endDate: Date } | null {
    let date: { startDate: Date; endDate: Date } | null = null;
    if (period?.type == TypeLimit.DATE_TO_DATE) {
      date = new DateBuilder('')
        .skipByUnit(period.skip_by_unit, period.skip_unit)
        .takeByUnit(period.take_by_unit, period.take_unit)
        .setPoint(period.start_point, period.start_date_unit, ResultDate.START_DATE)
        .setPoint(period.end_point, period.end_date_unit, ResultDate.END_DATE)
        .returnDate();
    }
    if (period?.type == TypeLimit.FROM_NOW) {
      date = new DateBuilder('')
        .skipByUnit(period.skip_by_unit, period.skip_unit)
        .takeByUnit(period.take_by_unit, period.take_unit)
        .returnDate();
    }
    if (!period?.type) throw new Error('No period type specified');
    return date;
  }

  getReefBack(from: string, to: string, amount: number) {
    return this.http.post('/api/v2/exchange/exchange', {
      from,
      to,
      amount,
    });
  }

  deposit(paymentId: string, amount: number, currency: string) {
    const params: any = {
      paymentSystemId: paymentId,
      amount: Number(amount),
      currency: currency,
    };
    return this.http.post('/api/v2/payments/deposit/charge', params);
  }
  depositColibrix(transactionId: string, cashforoParams: any, publicTransactionId: string) {
    return this.http.post('https://cashforo.com/zcolibrix-adapter/charge', {
      transactionId,
      publicTransactionId,
      ...cashforoParams,
    });
  }

  withdraw(payment: any, amount: number, currency: string, additionalFields: any) {
    const params: any = {
      paymentSystemId: payment?.id,
      amount: amount,
      currency: currency,
      metadata: JSON.stringify({
        ...additionalFields,
        env: 'prod',
        merchant: 'welle',
      }),
    };
    console.log(additionalFields);
    return this.http.post('/api/v2/payments/withdraw/charge', params);
  }
  setIframeDepositUrl(url: string) {
    this.iframeDepositOutput.emit(url);
  }
  postToCashforo(withdrawID: string, externalWithdrawId: string) {
    return this.http.post(`https://cashforo.com/main/withdraw-flat/update-external-id`, {
      withdrawId: `${withdrawID}`,
      externalWithdrawId: `${externalWithdrawId}`,
    });
  }
  canceledWithdrawal(data: {
    externalWithdrawId?: string;
    withdrawId?: string;
    isCanceledByUser?: boolean;
    cancelBy?: string;
  }) {
    return this.http.post(`https://cashforo.com/main/withdraw-flat/cancel`, {
      ...data,
    });
  }
  setAmount(amount: string) {
    return Number(amount).toFixed(2);
  }

  formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }
  filterDepBonuses(bonuses: any) {
    return bonuses.filter((elem: any) => elem?.Event?.indexOf('deposit') > -1);
  }
  sendPaymentMethod(payment: any) {
    this.payment.emit(payment);
  }
  sendDepositData(params: any) {
    this.makeDepositRegistration.emit(params);
  }

  setSortedCurrencies(currencies: any[], country: string): void {
    this.keystone
      .getGeoCurrenciesBySlug(country)
      .pipe(
        switchMap((response) => (response ? of(response) : this.keystone.getGeoCurrenciesBySlug('default'))),
        catchError(() => {
          return of(null);
        }),
        take(1)
      )
      .subscribe((geoCurrencies) => {
        if (!geoCurrencies) {
          this.storage.setSessionStorage('sortCurrencies', JSON.stringify(currencies));
        } else {
          this.sortCurrencies(currencies, geoCurrencies);
        }
      });
  }

  sortCurrencies(currencies: currencyInterface[], geoCurrencies: GeoCurrency) {
    const { excludeCurrencies = [], priorCurrencies = [] } = geoCurrencies;
    let filteredCurrencies = currencies.filter((currency) => !excludeCurrencies.includes(currency.name));
    if (priorCurrencies.length > 0) {
      const priorSorted = priorCurrencies
        .map((prior: string) => filteredCurrencies.find((currency) => currency.name === prior))
        .filter((currency): currency is currencyInterface => !!currency);
      const nonPriorCurrencies = filteredCurrencies.filter((currency) => !priorCurrencies.includes(currency.name));
      filteredCurrencies = [...priorSorted, ...nonPriorCurrencies];
    }
    this.storage.setSessionStorage('sortCurrencies', JSON.stringify(filteredCurrencies));
  }

  checkWithdrawDescription(params: any): withdrawDescription {
    if (typeof params === 'object') {
      const control = Object.keys(params)[0];
      return { control: control, labelWithoutTranslation: params[control] };
    }
    return { control: params, labelWithoutTranslation: `cashier.payments.lebels.${params}` };
  }
  findLimitByCurrency = (limits: any[], currency: string) =>
    limits?.find((item) => item.currency.some((curr: any) => curr.slug === currency));
}
