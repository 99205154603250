import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs';
import { checkUserExist } from '../actions/sign-up.actions';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Injectable()
export class checkUserExistEffect {
  constructor(
    private actions$: Actions,
    private authService: AuthService
  ) {}

  $checkUserExist = createEffect(() => {
    return this.actions$.pipe(
      ofType(checkUserExist['[checkUserExist]CheckUserExist']),
      mergeMap((action) => {
        return this.authService.checkRegisterParams(action).pipe(
          map((res: any) => {
            return checkUserExist['[checkUserExist]CheckUserExistSuccess']({
              data: { userData: action, errors: res },
            });
          })
        );
      })
    );
  });
}
