import { createReducer, on } from '@ngrx/store';
import { GetUser, SetUser } from '../actions/user.actions';

export const saveUserKey = 'saveUser';

export interface State {
  userProfile: any;
  loaded: boolean;
  isAuth: boolean;
}

export const initialState: State = {
  userProfile: null,
  loaded: false,
  isAuth: false,
};
export const reducer = createReducer(
  initialState,
  on(GetUser['[GetUser]GetUser'], (state) => {
    return {
      ...state,
      loaded: false,
    };
  }),
  on(SetUser['[SetUser]SetUserProfileSuccess'], (state, action) => {
    return {
      ...state,
      isAuth: true,
      loaded: true,
      userProfile: action.data,
    };
  }),
  on(SetUser['[SetUser]SetUserProfileFailure'], (state) => {
    return {
      ...state,
      isAuth: false,
      userInfo: null,
      loaded: true,
    };
  })
);
