import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class QueryService {
  constructor() {}

  getAllPromotions(lang: string, country: string) {
    return {
      query: `{ allPromotions (sortBy: order_ASC, where: { AND: [{isVisible: true}, {restrictedCountries_none: {name_i: "${country}"}}, { OR: [{allowedCountries_some: {name_i: "${country}"}}, {allowedCountries_every: {name: null}}]}] } ){ id , img, slug, description_l_${lang}, name_l_${lang}, short_l_${lang}, opengraphTitle_l_${lang}, opengraphDescription_l_${lang}, opengraphKeywords, opengraphImage, buttonClass, buttonAction } }`,
    };
  }

  getBannerPage(lang: string, slug: string) {
    return {
      query: `{ allBannerPages(where: {name:"${slug}"}) {id,content_l_${lang}}}`,
    };
  }
  getPromotionBySlug(slug: string, lang: string) {
    return {
      query: `{ allPromotions(where: {slug: "${slug}"}) { id , img, slug, description_l_${lang}, name_l_${lang}, short_l_${lang}, opengraphTitle_l_${lang}, opengraphDescription_l_${lang}, opengraphKeywords, opengraphImage, buttonClass, buttonAction } }`,
    };
  }
  getVerificationBySlug(slug: string, lang: string) {
    return {
      query: `{allVerifications(where:{slug: "${slug}"}){title_l_${lang}, code_l_${lang}} }`,
    };
  }
  getPageBySlugQuery(slug: string, lang: string) {
    return {
      query: `{allPages(where: {slug: "${slug}"}) { id,  name, slug,  isVisible, description_l_${lang}, opengraphTitle,   opengraphDescription,   opengraphKeywords,  opengraphImage } }`,
    };
  }
  getTopFaq(lang: string) {
    return {
      query: `{allFAQGroups(where: { isVisible: true }) {slug, order, title_l_${lang}, FAQs{slug, description_l_${lang}, title_l_${lang},}} }`,
    };
  }
  getFaqBySlugQuery(slug: string, lang: string) {
    return {
      query: `{allFAQGroups(where: {slug: "${slug}"}) {slug, order, title_l_${lang}, FAQs{slug, description_l_${lang}, title_l_${lang},}} }`,
    };
  }

  getIBansBySlug(slug: string) {
    return {
      query: `{ allAllowedIbans(where: {slug: "${slug}"}) {id , slug, AllowedIbans } }`,
    };
  }
  getAllTermsPages() {
    return {
      query: `{ allTermsPages(where: { isVisible: true }){ id, slug, isVisible, content, version, visibleName, pdf } }`,
    };
  }

  getAllPaymentMethods() {
    return {
      query: `{ allPaymentMethods(sortBy: order_ASC, where: { isVisible: true }){ name, image, depositMethod, depositLimit, withdrawMethod, withdrawLimit, country, depositFee } }`,
    };
  }

  getGeoCurrenciesBySlug(slug: string) {
    return {
      query: `{ allGeoCurrencies(where: {slug: "${slug}"}) { slug, priorCurrencies, excludeCurrencies } }`,
    };
  }
}
